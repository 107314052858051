import React, { useContext, useState, useEffect } from "react";
import OTPInput from "otp-input-react";
// import "../css/OtpVerification.css";
import "../css/Authentication.css";
import { MarketContext } from "../Context.jsx";

import { Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const OtpVerification = () => {
  const { token, loca, appname } = useContext(MarketContext);
  const [otp, setOtp] = useState(false);
  const [time, setTime] = useState(60);
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");
  const [third, setThird] = useState("");
  const [fourth, setFourth] = useState("");
  const [otpContent, setOtpContent] = useState("");
  const { email } = useLocation().state || 0;

  const navigation = useNavigate();

  const forgetpasscall = () => {
    navigation("/ForgetPassword");
  };

  const GenerateNewpinfn = () => {
    // let o = first + second + third + fourth;
    let o = otpContent;
    console.log(o);
    console.log(otp, time);

    let email = localStorage.getItem("email");
    console.log(email);
    let fp = { forget_password: { email: email, otp: o } };
    if (otp === true && time != 0) {
      axios
        .post(loca + "/marketplace/set/matchOtp", fp, {
          headers: { "market-application": appname },
        })
        .then(
          (resp) => {
            let optresp = resp.data;
            console.log(optresp);
            if ("Error" in optresp) {
              console.log(optresp.Error);
              toast.error(optresp.Error);
            } else {
              navigation("/Newpin");
            }
          },
          (error) => {
            navigation("/error");
            console.log(error);
          }
        );
    } else {
      toast.warning("resend otp");
    }
  };

  const clearinput = (n) => {
    switch (n) {
      case 1:
        setFirst("");
        break;
      case 2:
        setSecond("");
        break;
      case 3:
        setThird("");

        break;
      case 4:
        setFourth("");

        break;

      default:
        console.log("error in int");
        break;
    }
  };

  const fieldverify = (e, n) => {
    if (/\d/.test(e)) {
      setOtp(true);
      switch (n) {
        case 1:
          setFirst(e);
          console.log("lllllll" + e.length);
          if (e.length === 1) {
            document.getElementById("secondinp").focus();
          }
          break;
        case 2:
          setSecond(e);
          if (e.length === 1) {
            document.getElementById("thirdinp").focus();
          }
          break;
        case 3:
          setThird(e);
          if (e.length === 1) {
            document.getElementById("fourthinp").focus();
          }
          break;
        case 4:
          if (e.length === 1) {
            setFourth(e);
          }
          break;

        default:
          console.log("error in int");
          break;
      }
      return "verified";
    } else {
      setOtp(false);
      return "unverified";
    }
  };

  const sendOTPcallfn = () => {
    console.log("resend otp sent");
    if (email === "") {
      alert("check your email");
    } else {
      let fp = { forget_password: { email: email } };
      console.log(token);
      console.log("ggg " + fp.toString());
      axios
        .post(loca + "/marketplace/set/forgetpassword", fp, {
          headers: {
            "market-application": appname,
          },
        })
        .then(
          (resp) => {
            console.log(resp.data);
            const fpd = resp.data;
            console.log("gggggggggg" + JSON.stringify(fpd));
            //  let c = "Error" in fpd;
            //   console.log(c);
            console.log(fpd.Message === "OTP send successfully");
            if (fpd.Message === "OTP send successfully")
              toast.success(fpd.Message);

            if ("Error" in fpd) {
              alert(fpd.Error);
            } else {
              let e = fpd.email;
              console.log("forgettttttt" + JSON.stringify(e));
              localStorage.setItem("email", e);

              navigation("/otpverify", {
                state: { email: email },
              });
              /*  navigation("/setNewPassword", {
                state: { op: password, un: username,app:application }, */
            }
          },
          (error) => {
            navigation("/error");
            console.log(error);
          }
        );
    }
  };
  const handleResendOtp = () => {
    //  GenerateNewpinfn();
    sendOTPcallfn();
    setTime(60);
  };
  useEffect(() => {
    if (time > 0) {
      const interval = setInterval(() => {
        setTime(time - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [time]);

  return (
    <div className="parent-div">
      <Card className="bg-auth-1">
        <CardHeader className="border-0">
          <h3 className="text-center">OTP Verification</h3>
          <hr className="my-2" />
        </CardHeader>
        <CardBody className="text-center py-0">
          <div className="d-flex ps-2 mb-1 mt-2">
            <OTPInput
              value={otpContent}
              onChange={setOtpContent}
              autoFocus={true}
              OTPLength={4}
              otpType="number"
              inputStyles={{
                width: "50px",
                height: "50px",
                fontSize: "22px",
                fontWeight: "500",
                marginRight: "10px",
              }}
            />
          </div>
          {time === 0 ? (
            <div className="my-2">
              Didn't receive otp?{" "}
              <span
                className="text-warning underline cursor-pointer"
                onClick={handleResendOtp}
                // disabled={time > 0}
              >
                Resend OTP
              </span>
            </div>
          ) : (
            <p className="my-2">Time left: {time} seconds</p>
          )}

          <div className="form-group mt-4">
            <button
              className="btn btn-warning btn-width"
              onClick={(e) => GenerateNewpinfn(e.target.value)}
            >Verify</button>
          </div>
          <hr className="mt-3 mb-2" />
        </CardBody>
        <CardFooter className="border-0 pt-0">
          <div className="d-flex align-items-center gap-1 px-1 cursor-pointer">
            <i className="fa fa-arrow-left"></i>
            <span onClick={forgetpasscall}>Back</span>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
export default OtpVerification;
