import axios from "axios";
import React, {
  Component,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";

import { Modal, Button } from "react-bootstrap";
import "../css/ListComponent.css";
import { MarketContext } from "../Context";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import WorkInProgress from "./WorkInProgress";
import NewFilterCompo from "./NewFilterCompo";
import PrefCompo from "./PrefCompo";
import { ToastContainer, toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { useFilePicker } from "use-file-picker";
import ContextMenu from "./ContextMenu";
// import Modal from "react-modal";

const ListComponent = () => {
  const {
    token,
    loca,
    showAll,
    userDetails,
    contextMenu,
    listAccess,
    user,
    appname,
    imageloca,
    formHeight,
  } = useContext(MarketContext);
  const { openFilePicker, filesContent, clear } = useFilePicker({
    accept: [".txt", ".csv"],
  });
  const navigation = useNavigate();
  // const [all, setAll] = useState(showAll);
  const [record, setRecord] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [name, setName] = useState("");
  const [col_mn, setCol_mn] = useState([]);
  const [list, setlist] = useState([]);
  const [listRcd, setListRcd] = useState([]);
  const [showlist, setShowList] = useState(false);
  const [showbtn, setShowbtn] = useState(false);
  const [modal, setModal] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [ref_fields, setRef_Fields] = useState([]);
  // const [booleanfld, setBooleanFld] = useState([]);
  const [srch_column, setSrch_column] = useState("name");
  const [button, setButton] = useState([]);
  const [btnNamee, setbtnNamee] = useState("");
  const [url, setUrl] = useState("");
  const [mainrecord, setMainRecord] = useState({});
  const [sortColumn, setSortColumn] = useState({
    name: "",
    sort: true,
    init: false,
  });
  const [prefCall, setPrefCall] = useState([]);
  const [srch, setSrch] = useState("");
  const [filtarray, setFiltArray] = useState([]);
  const [filString, setfilString] = useState("");
  const [filter, setFilter] = useState();
  const [showContext, setShowContext] = useState(false);
  const [showListContext, setShowListContext] = useState(false);
  const [tableLabel, setTableLabel] = useState("");
  const [page_error, setPage_Error] = useState(false);
  const [error, setError] = useState("");
  const [pageMessage, setPageMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [timeline, setTimeline] = useState("");
  const [ClearButtonLoading, setClearButtonLoading] = useState(false);
  const [RunButtonLoading, setRunButtonLoading] = useState(false);
  const [pageClicked, setPageClicked] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageRecords, setPageRecords] = useState("");
  const [recordCount, setRecordCount] = useState(0);
  const [columnList, setColumnList] = useState([]);
  const [showPref, setShowPref] = useState(false);
  const [column_depend, setColumn_depend] = useState([]);
  const [tableName, setTableName] = useState("");
  const [searchParam, setSearchParam] = useSearchParams();
  const [recordId, setRecordId] = useState(0);

  let listName = useRef("").current;
  let menuX = useRef(0);
  let menuY = useRef(0);
  let filt1 = useRef();
  let checkMenu = useRef(false);

  // const openInNewtab = NavigateNewTab();
  //................ Functions ............//

  useEffect(() => {
    document
      .getElementsByTagName("body")
      .item(0)
      .addEventListener("mousedown", () => {
        setShowContext(false);
        setShowListContext(false);
      });
    getSortAndFilter();
  }, [searchParam, showAll]);

  const checkAccess = () => {
    let tabname = searchParam.get("tableName");
    let listCheck = listAccess.current.find((e) => e === tabname);
    if (listCheck) {
      return tabname;
    }
    return null;
  };

  const getListRecord = (isAccessable, listName, srrt, flt) => {
    if (isAccessable) {
      setTableName(listName);
      let pg = localStorage.getItem("pageClicked");
      sortColumn.name = srrt.column;
      sortColumn.sort = srrt.asc === "true" ? true : false;
      let p = 1;
      // let filt=[];
      let srt = srrt.asc;
      let srtPage = srrt.column;
      var filterstate = "";
      filt1.current = flt.filter;
      if (pg) {
        let pag = JSON.parse(pg);
        p = pag.page;
        filterstate = pag.filter;
        let sort = pag.sort;
        srt = sort.sort;
        if (sort.name !== "") {
          srtPage = sort.name;
        }
        setFiltArray([...filterstate]);
        setSortColumn(sort);
        localStorage.removeItem("pageClicked");
      }
      var farray = [];
      if (filterstate === "") {
        if (
          filt1.current === "" ||
          filt1.current === "undefined" ||
          filt1.current === undefined ||
          filt1.current === "null" ||
          filt1.current === null
        ) {
          let fl = {
            co: "",
            cl: "",
            mc: "",
            an: "",
            ct: "",
            af: "",
            rf: {
              id: "",
              value: "",
            },
            ch: [],
          };
          farray.push(fl);
          sortColumn.name = "";
          sortColumn.sort = true;
          filterstate = farray;
        } else {
          farray = filt1.current;
          filterstate = farray;
        }
        setFiltArray(farray);
        setTimeline(flt.timeLine);
      }

      var tablere = '{"formRecordList":[';
      tablere += '{"application":{"id":"2","name":"marketplace"}}';
      tablere += ',{"table":{"id":"","name":"' + listName + '"}}';
      tablere += ',{"records":[]}';
      tablere +=
        ',{"page":{"record_count":"0","page_count":"1",' +
        '"page_clicked":"' +
        p +
        '","page_records":"0"}}';
      tablere +=
        ',{"sort":{"asc":"' +
        srt +
        '","column":"' +
        srtPage +
        '","init":"true"}}';
      tablere += ',{"filter":' + JSON.stringify(filterstate) + "}";
      tablere += ',{"timeLine":"' + flt.timeLine + '"}]}';
      setList(tablere);
    }
  };

  const refresh = () => {
    getSortAndFilter();
    checkMenu.current = true;
    setLoading(true);
  };

  const back = () => {
    navigation(-1);
  };

  const menuFn = (body) => {
    let fn = new Function(
      [
        "back",
        "refresh",
        "exportData",
        "importData",
        "navigation",
        "tableName",
        "rid",
        "user",
      ],
      body
    );
    fn(
      back,
      refresh,
      exportData,
      importData,
      navigation,
      tableName,
      recordId,
      user
    );
  };

  const setContext = (val, x, y) => {
    if (val === 2) {
      menuX.current = x;
      menuY.current = y;
      setShowContext(true);
    } else {
      setShowContext(false);
    }
  };
  const setListContext = (val, x, y, rid) => {
    if (val === 2) {
      menuX.current = x;
      menuY.current = y;
      setRecordId(rid);
      setShowListContext(true);
    } else {
      setShowListContext(false);
    }
  };

  const handleShow = () => {
    setShowPref(true);
  };

  const handleClose = () => {
    setShowPref(false);
  };

  const getSortAndFilter = () => {
    listName = searchParam.get("tableName");
    axios
      .get(loca + "/marketplace/get/sortfilter/" + listName, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          let rsp = resp.data;
          let isAccessable = checkAccess() !== null;
          getListRecord(
            isAccessable,
            listName,
            rsp.value[0].sort,
            rsp.value[1]
          );
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleCloseRefresh = () => {
    handleClose();
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","name":"marketplace"}}';
    tablere += ',{"table":{"id":"","name":"' + tableName + '"}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
    tablere += ',{"filter":' + JSON.stringify(filtarray) + "}";
    tablere += ',{"timeLine":"' + timeline + '"}]}';
    setList(tablere);
  };

  const leftReadOnly = () => {
    if (pageClicked === 1) {
      return true;
    } else {
      return false;
    }
  };

  const rightReadOnly = () => {
    if (pageClicked === pageCount) {
      return true;
    } else {
      return false;
    }
  };

  const previousPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (pageClicked - 1) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const nextPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (pageClicked + 1) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const firstPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const lastPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      pageCount +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const getChoiceRcd = async (col_id) => {
    let chk = [];
    let ck = await axios
      .get(loca + "/marketplace/get/choice/" + col_id, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          let chk = res.choiceRecords;
          return chk;
        },
        (error) => {
          console.log(error);
        }
      );
    return ck;
  };

  const callfilter = async (farray, in_index, col_id) => {
    if (col_id !== -1) {
      if (
        farray[in_index].ct === "choice" &&
        farray[in_index].ch.length === 0
      ) {
        let chc = await getChoiceRcd(col_id).then((res) => {
          if (res.length > 0) {
            farray[in_index].ch = res;
            farray[in_index].an = res[0].name;
          } else {
            farray[in_index].ch = [];
          }
        });
        //  console.log(chcRcd.current);
      }
      // }
    }
    setFiltArray([...farray]);
  };

  const calltimeline = (timeline) => {
    setTimeline(timeline);
  };

  const showupdownbtn = (hd) => {
    var sarray = sortColumn;
    let flt = JSON.parse(JSON.stringify(filtarray));
    var clmn = "";
    var srt = "";
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';

    if (sarray.name === hd) {
      sarray.sort = !sarray.sort;
      for (var l = 0; l < list.length; l++) {
        for (var ll = 0; ll < list[l].heading.length; ll++) {
          if (list[l].heading[ll].name === hd) {
            clmn = list[l].heading[ll].name;
            break;
          }
        }
      }

      if (sarray.sort === true) {
        srt = '{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;
      } else {
        srt = '{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;
      }
    } else {
      sarray.name = hd;
      sarray.sort = true;
      for (var li = 0; li < list.length; li++) {
        for (var lll = 0; lll < list[li].heading.length; lll++) {
          if (list[li].heading[lll].name === hd) {
            clmn = list[li].heading[lll].name;
            break;
          }
        }
      }
      if (sarray.sort === true) {
        srt += '{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;
      } else {
        srt +=
          '{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;
      }
    }
    // setCol_mn({ sortColumn: sarray });
    pp += ',{"filter":' + JSON.stringify(flt) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';
    storeSrot(srt, pp);
  };

  const storeSrot = (sort, pp) => {
    let js = { tableName: tableName, sort: sort };
    axios
      .post(loca + "/marketplace/set/sort", js, {
        headers: {
          Authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (rsp) => {
          setList(pp);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const saveFilter = () => {
    let ft = { filter: filtarray, timeLine: timeline };
    let js = { tableName: tableName, filter: ft };
    axios
      .post(loca + "/marketplace/save/filter", js, {
        headers: {
          Authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (rsp) => {
          let resp = rsp.data;
          console.log(resp);

          toast("Filter saved", {
            type: "success",
            position: "top-center",
            theme: "colored",
          });
        },
        (error) => {
          console.log(error);
        }
      );
  };
  const removeFilter = () => {
    let js = { tableName: tableName };
    axios
      .post(loca + "/marketplace/remove/filter", js, {
        headers: {
          Authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (rsp) => {
          let resp = rsp.data;
          setShowList(!showlist);
          setShowbtn(!showbtn);
          getSortAndFilter();
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const setSort = () => {
    var clmn = "";
    var pp = "";
    var srt = sortColumn.name;

    if (srt !== "") {
      for (var l = 0; l < list.length; l++) {
        for (var ll = 0; ll < list[l].heading.length; ll++) {
          if (list[l].heading[ll].name === sortColumn.name) {
            clmn = list[l].heading[ll].name;
            break;
          }
        }
      }
      if (sortColumn.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
      return pp;
    } else {
      pp +=
        ',{"sort":{"asc":"true","column":"' +
        list[0].heading[0].name +
        '","init":"false"}}';
    }
    return pp;
  };

  //.................. Api Call ...............//

  const processListRecords = (listrecord) => {
    var columnarry = [];
    var hd = [];
    var va_ll = [];
    if ("Error" in listrecord) {
    } else {
      var scnd = listrecord.formRecordList[2];
      if ("Error" in scnd) {
        if (scnd.Error === "No record found.") {
          setlist([]);
        }
      } else {
        setTableLabel(listrecord.formRecordList[1].table.label);

        var pageClicked1 = parseInt(
          listrecord.formRecordList[3].page.page_clicked
        );
        var pageCount1 = parseInt(listrecord.formRecordList[3].page.page_count);
        var page_records1 = listrecord.formRecordList[3].page.page_records;
        var record_count1 = parseInt(
          listrecord.formRecordList[3].page.record_count
        );
        var fltarr = listrecord.formRecordList[5].filter;
        var tmln = listrecord.formRecordList[6].timeLine;
        var filterString = "";
        var leng = fltarr.length;
        for (var f = 0; f < fltarr.length; f++) {
          if (leng === 1 && fltarr[f].cl === "") {
            break;
          } else {
            if (f > 0) {
              filterString += fltarr[f].af + " ";
            }
            filterString += fltarr[f].cl + " ";
            filterString += fltarr[f].mc + " ";
            if (fltarr[f].ct === "reference") {
              filterString += fltarr[f].rf.value + " ";
            } else {
              filterString += fltarr[f].an + " ";
            }
          }
        }
        for (var i = 0; i < listrecord.formRecordList[2].records.length; i++) {
          var in_vl = [];
          var record_id;
          for (
            var j = 0;
            j < listrecord.formRecordList[2].records[i].record.length;
            j++
          ) {
            if (j === 0) {
              record_id =
                listrecord.formRecordList[2].records[i].record[j].value;
            } else if (j === 1) {
              if (
                listrecord.formRecordList[2].records[i].record[j].type ===
                "reference"
              ) {
                in_vl.push({
                  value:
                    listrecord.formRecordList[2].records[i].record[j].value
                      .value,
                  type: listrecord.formRecordList[2].records[i].record[j].type,
                  firstrecord: true,
                  table: listrecord.formRecordList[1].table.name,
                  r_id: record_id,
                  name: listrecord.formRecordList[2].records[i].record[j].value
                    .name,
                });
              } else {
                in_vl.push({
                  value:
                    listrecord.formRecordList[2].records[i].record[j].value,
                  type: listrecord.formRecordList[2].records[i].record[j].type,
                  firstrecord: true,
                  table: listrecord.formRecordList[1].table.name,
                  r_id: record_id,
                  name: listrecord.formRecordList[2].records[i].record[j].name,
                });
              }
            } else {
              if (
                listrecord.formRecordList[2].records[i].record[j].type ===
                "reference"
              ) {
                in_vl.push({
                  value:
                    listrecord.formRecordList[2].records[i].record[j].value,
                  type: listrecord.formRecordList[2].records[i].record[j].type,
                  firstrecord: false,
                  table: listrecord.formRecordList[1].table.name,
                  r_id: record_id,
                  name: listrecord.formRecordList[2].records[i].record[j].name,
                });
              } else {
                in_vl.push({
                  value:
                    listrecord.formRecordList[2].records[i].record[j].value,
                  type: listrecord.formRecordList[2].records[i].record[j].type,
                  firstrecord: false,
                  table: listrecord.formRecordList[1].table.name,
                  r_id: record_id,
                  name: listrecord.formRecordList[2].records[i].record[j].name,
                });
              }
            }
          }
          in_vl.splice(0, 0, { ref: false });
          va_ll.push({ colr: in_vl });
        }
        let col_list = [];
        for (var c = 0; c < listrecord.formRecordList[9].column.length; c++) {
          if (listrecord.formRecordList[9].column[c].type === "String") {
            col_list.push({
              label: listrecord.formRecordList[9].column[c].label,
              name: listrecord.formRecordList[9].column[c].name,
              type: listrecord.formRecordList[9].column[c].type,
            });
          }
        }
        for (
          var p = 0;
          p < listrecord.formRecordList[10].preference.length;
          p++
        ) {
          hd.push({
            label: listrecord.formRecordList[10].preference[p].label,
            name: listrecord.formRecordList[10].preference[p].name,
            type: listrecord.formRecordList[10].preference[p].type,
          });
        }
        hd.splice(0, 0, { ref: false });
        columnarry.push({ heading: hd, rcd: va_ll });
        let column = listrecord.formRecordList[9].column;
        if ("Error" in column) {
          setPage_Error(true);
          setError(column.Error);
          setLoading(false);
          toast(column.Error, {
            type: "error",
            position: "bottom-center",
          });
        } else {
          var cla = JSON.parse(
            JSON.stringify(listrecord.formRecordList[9].column)
          );
          cla.unshift({ label: "None", name: "none" });
          setlist(columnarry);
          setListRcd(listrecord);
          setPageClicked(pageClicked1);
          setPageCount(pageCount1);
          setPageRecords(page_records1);
          setRecordCount(record_count1);
          setCol_mn(cla);
          setPrefCall(column);
          setFilter(fltarr);
          setfilString(filterString);
          setTimeline(tmln);
          setClearButtonLoading(false);
          setRunButtonLoading(false);
          setButton(listrecord.formRecordList[11].button);
          setColumnList(col_list);
          setLoading(false);
        }
      }
    }
  };

  const setList = (tablere) => {
    axios
      .post(loca + "/marketplace/get/multiple/record", tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((resp) => {
        const listrecord = resp.data;
        processListRecords(listrecord);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast("Something went wrong", {
          type: "error",
          theme: "colored",
          position: "bottom-center",
        });
      });
  };

  const changeSearch = (val) => {
    if (val === "") {
      setSrch("");
      filterClear();
    } else {
      setSrch(val);
    }
  };

  const callSearchbtn = () => {
    var type = "";
    var label = "";
    for (var c = 0; c < columnList.length; c++) {
      if (srch_column === columnList[c].name) {
        type = columnList[c].type;
        label = columnList[c].label;
      }
    }
    let filter = [
      {
        ct: type,
        af: "",
        mc: "=",
        cl: label,
        co: srch_column,
        an: srch,
        rf: { id: "", value: "" },
      },
    ];
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","name":"marketplace"}}';
    fs += ',{"table":{"id":"","name":"' + tableName + '","label":""}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id","init":"false"}}';
    fs += ',{"filter":' + JSON.stringify(filter) + "}";
    fs += ',{"timeLine":""}]}';
    setFiltArray([...filter]);
    setList(fs);
  };

  const callform = (nam, tab, r_id) => {
    if (nam === "first") {
      let pg = { page: pageClicked, filter: filtarray, sort: sortColumn };
      localStorage.setItem("pageClicked", JSON.stringify(pg));
      navigation({
        pathname: user + "/form",
        search: createSearchParams({
          rty: "record",
          tableName: tab,
          rid: r_id,
        }).toString(),
      });

      // showFormCompo(tab, r_id, "record");
    } else if (nam === "second") {
    } else if (nam === "new") {
      navigation({
        pathname: user + "/form",
        search: createSearchParams({
          // state: { rty: "new", tableName: tab, rid: 0 },
          rty: "new",
          tableName: tab,
          rid: 0,
        }).toString(),
      });
      // navigation("/form", );
      // showFormCompo(tab, 0, "new");
    }
  };

  const selectedAllRows = (checked) => {
    let rcd = list[0].rcd;
    if (checked) {
      list[0].heading[0].ref = true;
      for (let i = 0; i < rcd.length; i++) {
        list[0].rcd[i].colr[0].ref = true;
      }
      setlist([...list]);
    } else {
      list[0].heading[0].ref = false;
      for (let i = 0; i < rcd.length; i++) {
        list[0].rcd[i].colr[0].ref = false;
      }
      setlist([...list]);
    }
  };

  const selectedAction = (url) => {
    if (list[0].rcd.length > 0) {
      if (url === "/marketplace/delete/record") {
        setbtnNamee("Delete");
        setModal(true);
        setUrl(url);
      } else {
        selectAction(url);
      }
    }
  };

  const selectedRows = (checked, index) => {
    list[0].rcd[index].colr[0].ref = checked;
    if (!checked) {
      list[0].heading[0].ref = false;
    }
    setlist([...list]);
  };

  const selectAction = (url) => {
    setIsClick(true);
    let rcd = list[0].rcd;
    let postBody = [];
    for (let i = 0; i < rcd.length; i++) {
      if (rcd[i].colr[0].ref) {
        // rcd[i].colr.splice(0,1)
        postBody.push(
          rcd[i].colr[1].r_id
          // table: rcd[i].colr[1].table
        );
        continue;
      }
    }
    var jso = {
      url: url,
      table: rcd[0].colr[1].table,
      record: postBody,
    };
    axios
      .post(loca + "/marketplace/selectedAction", jso, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          const record = resp.data;
          if (record.Error) {
            listBtns.current.value = "none";
            toast(record.Error, {
              position: "top-center",
              theme: "colored",
              type: "error",
              style: {
                marginBottom: userDetails.OS !== "null" ? 12 : 0,
              },
            });
          } else {
            if (record.message) {
              toast(record.message, {
                position: "top-center",
                theme: "colored",
                type: "success",
                style: {
                  marginBottom: userDetails.OS !== "null" ? 12 : 0,
                },
              });
            } else {
              toast(record, {
                position: "top-center",
                theme: "colored",
                type: "success",
                style: {
                  marginBottom: userDetails.OS !== "null" ? 12 : 0,
                },
              });
            }
            refresh();
            // setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
          setModal(false);
          toast("Something went wrong", {
            position: "top-center",
            theme: "colored",
            type: "error",
          });
        }
      )
      .finally(() => {
        setModal(false);
        setIsClick(false);
      });

    if (url === "1") {
    }
  };

  // const dismiss = () => {
  //   unmountMe();
  // }

  const searchColumn = (e) => {
    // this.setState({ srch_column: e.target.value });
    setSrch_column(e.target.value);
  };

  const filterItem = () => {
    setShowList(!showlist);
    setShowbtn(!showbtn);
  };

  const filterClear = () => {
    var flt = {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
    };
    setFiltArray([flt]);

    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify([flt]) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const filtersubmit = () => {
    setLoading(true);
    sortColumn.sort = true;
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","name":"marketplace"}}';
    fs += ',{"table":{"id":"","name":"' + tableName + '"}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id","init":"false"}}';
    fs += ',{"filter":' + JSON.stringify(filtarray) + "}";
    fs += ',{"timeLine":"' + timeline + '"}]}';
    let len = filtarray.length;
    if (len > 0 && filtarray[len - 1].co !== "") {
      if (
        filtarray[len - 1].an !== "" ||
        (filtarray[len - 1].rf && filtarray[len - 1].rf.value !== "") ||
        (filtarray[len - 1].dc && filtarray[len - 1].dc.value !== "")
      ) {
        setList(fs);
      }
    } else {
      setList(fs);
    }
  };

  const callbtn = (nam) => {
    var btn = button;
    var mnrecord = mainrecord;
    for (var ij = 0; ij < btn.length; ij++) {
      if (btn[ij].value === nam) {
        var btntype = btn[ij].buttonWebType;
        var nextPage = btn[ij].returnWebLocation;
        var callType = btn[ij].call_type;
        var token = localStorage.getItem("token");

        setPage_Error(false);
        setError("");
        setLoading(true);
        setPageMessage(false);
        setMessage("");
        if (nextPage === "NextPage") {
          callform("new", tableName, 0);
        } else {
          mnrecord.formRecord[2].record = record;
          axios
            .post(loca + btn[ij].webUrl, mnrecord, {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + token,
                "market-application": appname,
              },
            })
            .then((resp) => {
              const rcd = resp.data;
              setLoading(false);
              var msg = rcd.formRecord[4].message;
              if (msg !== "") {
                // setState({ page_message: true, message: msg });
                setPageMessage(true);
                setMessage(msg);
              }
              if (btntype === "NextPage") {
                // // var nextP = btn[ij].nextPage;
                // var nextP = rcd.formRecord[3].button.nextPage;
                // if (nextP === "List") {
                //   // callNextPage();
                // }
              }
            });
        }

        // } else {
        // 	setState({
        // 		page_error: true,
        // 		error: error_String,
        // 	});
        // 	document.body.scrollTop = 0;
        // 	document.documentElement.scrollTop = 0;
        // 	// props.unmountMe();
        // }
      }
    }
  };

  const download = (res, tablenm) => {
    const element = document.createElement("a");
    element.href = `data:text/csv;charset=utf-8,${res}`;
    element.download = tablenm;
    element.click();
    element.remove();
  };

  const exportData = () => {
    axios
      .post(loca + "/data/export", listRcd.formRecordList, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        const record = resp.data;
        download(record, listRcd.formRecordList[1].table.name);
      });
  };
  const [showFilePicker, setShowFilePicker] = useState(false);
  const importData = () => {
    setShowFilePicker(true);
  };

  const importt = (files) => {
    if (
      files.length !== 0 &&
      files[0].name.replace(/(\(\d+\))|(\.csv$)/g, "").trim() === tableName
    ) {
      setIsClick(true);
      const blob = new Blob([files[0].content], { type: "text/csv" });
      const file = new File([blob], files[0].name, {
        type: "text/csv",
      });
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post(loca + "/data/import", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: "Bearer " + token,
          },
        })
        .then(
          (resp) => {
            const record = resp.data;
            toast(record, {
              position: "top-center",
              theme: "colored",
              type: "success",
              style: {
                marginBottom: userDetails.OS !== "null" ? 12 : 0,
              },
            });
            clear();
            refresh();
          },
          (error) => {
            setLoading(false);
            setModal(false);
            toast("Something went wrong", {
              position: "top-center",
              theme: "colored",
              type: "error",
            });
          }
        )
        .finally(() => {
          setShowFilePicker(false);
          setIsClick(false);
        });
    } else {
      toast("Table not match", {
        position: "top-center",
        theme: "colored",
        type: "error",
      });
      setShowFilePicker(false);
      clear();
    }
  };

  const listBtns = useRef(null);

  const processText = (text) => {
    return String(text).replace(/\\n/g, " ");
  };

  if (!loading) {
    return (
      <div className="overfl-tab">
        <Modal show={modal} onHide={() => setModal(!modal)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm {btnNamee}</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setModal(!modal)}
              disabled={isClick}
              style={{ backgroundColor: isClick ? "gray" : "" }}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => selectAction(url)}
              disabled={isClick}
              style={{ backgroundColor: isClick ? "gray" : "" }}
            >
              {btnNamee}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showFilePicker}
          onHide={() => setShowFilePicker(!showFilePicker)}
        >
          <Modal.Header closeButton onClick={() => clear()}>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className="" sty>
            <div>
              {filesContent.length === 0 && (
                <button onClick={() => openFilePicker()}>Select files </button>
              )}
              {filesContent.map((file, index) => (
                <div key={index}>
                  {/* <h2>{file.name}</h2> */}
                  {/* <img alt={file.name} src={file.content}></img> */}
                  <div key={file.name}>{file.name}</div>
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => clear()}>
              Clear
            </Button>
            <Button
              variant="danger"
              onClick={() => importt(filesContent)}
              disabled={isClick}
              style={{ backgroundColor: isClick ? "gray" : "" }}
            >
              Upload
            </Button>
          </Modal.Footer>
        </Modal>

        {loading === true ? (
          <WorkInProgress />
        ) : (
          <div>
            {/* <ToastContainer
              position="top-center"
              autoclose={1800}
            /> */}
            {isMobile === true ? (
              <div className="row bck bck-rel">
                <div className="col-lg mid">
                  <span className="obj_head ">{tableLabel}</span>
                </div>
                <div className="col-lg filter-ic p-0">
                  {showlist === false && (
                    <select
                      style={{
                        // lineHeight: "0.8em",
                        width: "10em",
                        display: "inline",
                      }}
                      className="form-select namelist-mob"
                      aria-label="Default"
                      value={srch_column}
                      onChange={(e) => searchColumn(e)}
                    >
                      {columnList.map((obj2, index) => (
                        <option key={index} value={obj2.name}>
                          {obj2.label}
                        </option>
                      ))}
                    </select>
                  )}

                  {showlist === false && (
                    <input
                      className="srch form-control"
                      type="search"
                      placeholder="Search"
                      value={srch}
                      id="b4-search"
                      onChange={(e) => changeSearch(e.target.value)}
                    ></input>
                  )}

                  {showlist === false && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Search"
                      onClick={() => callSearchbtn()}
                      style={{
                        height: document
                          .getElementById("b4-search")
                          ?.getBoundingClientRect().height,
                      }}
                    ></input>
                  )}
                </div>
                <div className="col-lg martop10 disfl filter-ic">
                  <i
                    className="fa fa-filter vlpointer pdtop5"
                    onClick={filterItem}
                  ></i>
                  <i
                    className="fa fa-cog vlpointer cog_pd pdtop5"
                    onClick={handleShow}
                  ></i>
                  <div className="" style={{ textAlign: "end" }}>
                    <span style={{ marginLeft: "0.5em" }}>
                      <input
                        className=" mybt"
                        type="button"
                        value="<<"
                        onClick={firstPage}
                        disabled={leftReadOnly()}
                      />
                      <input
                        className=" mybt"
                        type="button"
                        value="<"
                        onClick={previousPage}
                        disabled={leftReadOnly()}
                      />
                    </span>
                    <span>
                      <input
                        className="in-put"
                        type="text"
                        readOnly={true}
                        value={pageRecords}
                      ></input>
                    </span>
                    <span className="text-uppercase spfont"> of </span>
                    <span className="rcd_count spfont">{recordCount}</span>
                    <span>
                      <input
                        className=" mybt"
                        type="button"
                        value=">"
                        onClick={nextPage}
                        disabled={rightReadOnly()}
                      />
                    </span>
                    <span>
                      <input
                        className=" mybt"
                        type="button"
                        value=">>"
                        disabled={rightReadOnly()}
                        onClick={lastPage}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-lg martop10">
                  {button.length > 0 &&
                    button.map((obj, oo_i) => (
                      <input
                        type="button"
                        className="csm_btn csm_btn_pri col-md-2 sub-btn"
                        key={oo_i}
                        value={obj.name}
                        onClick={(e) => callbtn(obj.value)}
                      ></input>
                    ))}
                  {showlist === true && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Run"
                      onClick={filtersubmit}
                    ></input>
                  )}

                  {showlist === true && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Clear"
                      onClick={filterClear}
                    ></input>
                  )}
                </div>
                {/* <div className="col-lg martop10">
                  {showlist === true && (
                   
                  )}
                </div> */}
              </div>
            ) : (
              <div
                className="row bck bck-rel"
                onContextMenu={(e) => {
                  e.preventDefault();
                  // m_rid.current = obj.id;
                  setContext(
                    e.button,
                    e.nativeEvent.pageX,
                    e.nativeEvent.pageY
                  );
                }}
              >
                <div>
                  {showContext && (
                    <ContextMenu
                      menuX={menuX}
                      menuY={menuY}
                      contextMenu={contextMenu}
                      menuFn={menuFn}
                      excluded_menu={["copy", "paste", "open_in_new_tab"]}
                      conditionFn={true}
                    />
                  )}
                  {showListContext && (
                    <ContextMenu
                      menuX={menuX}
                      menuY={menuY}
                      contextMenu={contextMenu}
                      menuFn={menuFn}
                      excluded_menu={[
                        "copy",
                        "paste",
                        "refresh",
                        "form_view_setUp",
                        "back",
                        "export_data",
                        "import_data",
                        "configure_all",
                      ]}
                      conditionFn={true}
                    />
                  )}
                </div>
                <div className="col-lg filter-ic p-0">
                  <i
                    className="fa fa-filter vlpointer"
                    onClick={filterItem}
                  ></i>

                  <i
                    className="fa fa-cog vlpointer cog_pd"
                    onClick={handleShow}
                  ></i>

                  {showlist === true && (
                    <input
                      className=" insrtbtn2 btn btn btn-primary btn-sm"
                      type="button"
                      value="Run"
                      onClick={filtersubmit}
                    ></input>
                  )}

                  {showlist === true && (
                    <input
                      className=" insrtbtn2 btn btn btn-primary btn-sm"
                      type="button"
                      value="Clear"
                      onClick={filterClear}
                    ></input>
                  )}

                  {showlist === false && (
                    <select
                      style={{
                        width: "10em",
                        display: "inline",
                        lineHeight: "0.8em",
                      }}
                      className="form-select"
                      aria-label="Default"
                      value={srch_column}
                      onChange={(e) => searchColumn(e)}
                    >
                      {columnList.map((obj2, index) => (
                        <option key={index} value={obj2.name}>
                          {obj2.label}
                        </option>
                      ))}
                    </select>
                  )}

                  {showlist === false && (
                    <input
                      className="srch form-control"
                      type="search"
                      aria-label="Search"
                      id="b4-search"
                      value={srch}
                      onChange={(e) => changeSearch(e.target.value)}
                      style={{
                        height: "1.73rem",
                      }}
                    ></input>
                  )}

                  {showlist === false && (
                    <button
                      className=" insrtbtn2 btn btn btn-primary btn-sm"
                      type="button"
                      onClick={() => callSearchbtn()}
                      style={{
                        height: "1.73rem",
                      }}
                    >
                      Search
                    </button>
                  )}
                </div>
                <div className="col-lg mid">
                  <p className="obj_head m-0">{tableLabel}</p>
                </div>
                <div className="col-lg bt_padd ">
                  <div>
                    <select
                      ref={listBtns}
                      style={{ height: "1.73rem" }}
                      onChange={(e) => {
                        e.target.value !== "none" &&
                          selectedAction(e.target.value);
                      }}
                    >
                      <option value={"none"}>None</option>
                      {button.length > 0 &&
                        button.map(
                          (obj, oo_i) =>
                            obj.ui_type === "selected_action" && (
                              <option key={oo_i} value={obj.webUrl}>
                                {obj.value}
                              </option>
                            )
                        )}
                    </select>
                  </div>
                  {button.length > 0 &&
                    button.map(
                      (obj, oo_i) =>
                        obj.ui_type === "market_button" && (
                          <button
                            className=" insrtbtn2 btn btn btn-primary btn-sm"
                            key={oo_i}
                            onClick={(e) => callbtn(obj.value)}
                            style={{
                              height: "1.73rem",
                            }}
                          >
                            {obj.name}
                          </button>
                        )
                    )}
                  <div style={{ display: "flex" }}>
                    <input
                      className=" mybt"
                      type="button"
                      value="<<"
                      onClick={firstPage}
                      disabled={leftReadOnly()}
                    />
                    <input
                      className=" mybt"
                      type="button"
                      value="<"
                      onClick={previousPage}
                      disabled={leftReadOnly()}
                    />

                    <span className="in-put">{pageRecords}</span>
                    <span className="text-uppercase spfont"> of </span>

                    <span className="rcd_count spfont">{recordCount}</span>
                    <input
                      className=" mybt"
                      type="button"
                      value=">"
                      onClick={nextPage}
                      disabled={rightReadOnly()}
                    />
                    <input
                      className=" mybt"
                      type="button"
                      value=">>"
                      disabled={rightReadOnly()}
                      onClick={lastPage}
                    />
                  </div>
                </div>
              </div>
            )}
            {showlist === false && filString !== "" && (
              <div style={{ textAlign: "start" }}>{filString}</div>
            )}
            {showlist === true && (
              <div>
                <div className="d-flex mb-2">
                  <input
                    className=" btn btn-sm btn-primary m-0 py-1 ms-2 mt-2"
                    type="button"
                    value="Save Filter"
                    // disabled={rightReadOnly()}
                    onClick={saveFilter}
                  />

                  <input
                    className="btn btn-sm btn-primary m-0 py-1 ms-2 mt-2"
                    type="button"
                    value="Remove Filter"
                    // disabled={rightReadOnly()}
                    onClick={removeFilter}
                  />
                </div>
                <NewFilterCompo
                  showlist={showlist}
                  col_mn={col_mn}
                  call_fil={(filtarray, in_index, col_id) => {
                    callfilter(filtarray, in_index, col_id);
                  }}
                  filtarray={filtarray}
                  setFiltArray={setFiltArray}
                  tableName={tableName}
                  timeline={timeline}
                  setTimeLine={(timeline) => calltimeline(timeline)}
                  setScript={(script) => {}}
                  isScript={false}
                  col_depend={column_depend}
                  setColumn_depend={setColumn_depend}
                />
              </div>
            )}

            {list.map((lstobj, lst_i) => (
              <div
                className="heading_top table_set overflow-auto"
                style={{ height: formHeight - 10, position: "relative" }}
                key={lst_i}
              >
                <table
                  className="table table-bordered table-striped table-hover p-1"
                  style={{ borderTop: "1px solid gray" }}
                >
                  <thead
                    className="table-dark"
                    style={{ position: "sticky", top: "-1px" }}
                  >
                    <tr className="obj_name">
                      {lstobj.heading.map(
                        (obj, obj_i) =>
                          obj.type !== "group_key_value" &&
                          obj.type !== "key_value" &&
                          obj.type !== "password" && (
                            <th
                              key={obj_i}
                              className="vlpointer  px-2 py-0"
                              style={{ verticalAlign: "middle" }}
                            >
                              {obj_i === 0 ? (
                                <span>
                                  <input
                                    type="checkbox"
                                    checked={obj.ref}
                                    onChange={(e) => {
                                      selectedAllRows(e.target.checked);
                                    }}
                                  ></input>
                                </span>
                              ) : (
                                <div
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    padding: "0.5rem",
                                    textWrap: "nowrap",
                                  }}
                                  onClick={(e) => {
                                    showupdownbtn(obj.name);
                                  }}
                                >
                                  {obj.label}
                                  {sortColumn.name === obj.name &&
                                    sortColumn.sort === true && (
                                      <i className=" icpadding fa fa-arrow-up"></i>
                                    )}
                                  {sortColumn.name === obj.name &&
                                    sortColumn.sort === false && (
                                      <i className=" icpadding fa fa-arrow-down"></i>
                                    )}
                                </div>
                              )}
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {lstobj.rcd.map((objj, objj_i) => (
                      <tr className="obj_value" key={objj_i}>
                        {objj.colr.map(
                          (objr, objr_i) =>
                            objr.type !== "key_value" &&
                            objr.type !== "group_key_value" &&
                            objr.type !== "password" && (
                              <td
                                key={objr_i}
                                className={
                                  objr.firstrecord === true
                                    ? "val_pad val_under vlpointer px-2"
                                    : "val_pad text-center px-2"
                                }
                                onClick={
                                  objr.firstrecord === true
                                    ? (e) =>
                                        callform("first", objr.table, objr.r_id)
                                    : (e) => callform("second", "", "")
                                }
                                onContextMenu={(e) => {
                                  e.preventDefault();
                                  setListContext(
                                    e.button,
                                    e.nativeEvent.pageX,
                                    e.nativeEvent.pageY,
                                    objr.r_id
                                  );
                                }}
                              >
                                {objr_i === 0 ? (
                                  <span>
                                    <input
                                      type="checkbox"
                                      checked={objr.ref}
                                      onChange={(e) => {
                                        selectedRows(e.target.checked, objj_i);
                                      }}
                                    ></input>
                                  </span>
                                ) : objr.type === "image_upload" ? (
                                  objr.value !== "null" && objr.value !== "" ? (
                                    <img
                                      className="list-images"
                                      // style={{
                                      //   height: "90px",
                                      //   width: "100%",
                                      //   objectFit: "contain",
                                      // }}
                                      src={imageloca + objr.value}
                                      alt=""
                                    />
                                  ) : (
                                    <span>No Image</span>
                                  )
                                ) : objr.type === "video" ? (
                                  <video
                                    loop
                                    className="user-video-list"
                                    controls
                                  >
                                    <source
                                      src={imageloca + objr.value}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : objr.type === "reference" ? (
                                  <span>
                                    {objr.value.name !== "null"
                                      ? objr.value.name
                                      : ""}
                                  </span>
                                ) : objr.type === "long_description" ? (
                                  <span>
                                    {objr.value !== "null"
                                      ? processText(objr.value)
                                      : ""}
                                  </span>
                                ) : (
                                  <span>
                                    {(objr.type === "filter" ||
                                      objr.type === "json") &&
                                    objr.value !== null
                                      ? JSON.stringify(objr.value.name)
                                      : objr.value === "null"
                                      ? ""
                                      : objr.value}
                                  </span>
                                )}
                              </td>
                            )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {lstobj.rcd.length === 0 && (
                  <div
                    style={{
                      fontWeight: "bold",
                      textDecoration: "none",
                      fontStyle: "normal",
                    }}
                  >
                    No Record Found
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        <Modal size="xl" show={showPref} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Personalized List Column</Modal.Title>
          </Modal.Header>
          <Modal.Body className="mod-ht">
            <PrefCompo
              pref="user"
              setRefer={(val) => setRef_Fields(val)}
              colarray={prefCall}
              tablename={tableName}
              handleClose={() => handleCloseRefresh()}
            ></PrefCompo>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  } else {
    return <WorkInProgress />;
  }
};

export default ListComponent;
