export const ApplicationProperties = {
    appname: "marketplace",
    tokenname: "marketAdminToken",
    loca: process.env.REACT_APP_LOCA,
    // loca: "http://192.168.0.118:8084",
    // imageloca: "https://imgs.mufama.com/",
    imageloca: process.env.REACT_APP_IMAGE_LOCA,
  };
  
  
  
  