import axios from "axios";
import React, { useContext, useEffect, useState, useRef } from "react";
import { MarketContext } from "../Context";
import "../css/UserPref.css";
import { isMobile, isTablet } from "react-device-detect";

const PrefCompo = ({ handleClose, pref, colarray, tablename }) => {
  const { token, loca, appname } = useContext(MarketContext);
  const firstRender = useRef(false);
  const oneRef = useRef(false);
  const [columnarray, setColumnArray] = useState(colarray);
  const [isColArrSelected, setIsColArrSelected] = useState(false);
  const [isColArr2Selected, setIsColArr2Selected] = useState(false);
  const [columnarray2, setColumnarray2] = useState([]);
  const [prefarray, setPrefArray] = useState([]);
  const [json, setJson] = useState({});
  const ind = useRef(0);

  useEffect(() => {
    getPrefRecord();
  }, []);

  useEffect(() => {
    if (firstRender.current && oneRef.current) {
      setColumn();
    } else {
      firstRender.current = true;
      oneRef.current = true;
    }
  }, [prefarray]);

  const getPrefRecord = () => {
    axios
      .get(loca + "/marketplace/get/pref/" + pref + "/" + tablename, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((resp) => {
        var sysrecord = resp.data;
        if (pref === "user") {
          setPrefArray([...sysrecord.userPreference[2].column]);
          setJson(sysrecord);
        } else if (pref === "owner") {
          setPrefArray([...sysrecord.ownerPreference[2].column]);
          setJson(sysrecord);
        }
      });
  };

  const setColumn = () => {
    var clm = columnarray;
    var clm2 = columnarray2;
    var prf = prefarray;
    for (var ii = 0; ii < clm.length; ii++) {
      clm[ii].check = false;
    }
    for (var i = 0; i < clm.length; i++) {
      for (var p = 0; p < prf.length; p++) {
        if (clm[i].name === prf[p].name) {
          clm[i].check = true;
          var jj = {
            label: clm[i].label,
            co: prf[p].co,
          };
          clm2.push(jj);
          // clm.splice(i,1)
        }
      }
    }

    // let clm22 = clm2.sort((a, b) => (a.co > b.co ? 1 : -1));
    let clmLeft = clm.sort((a, b) => a.name.localeCompare(b.name));
    setColumnArray([...clmLeft]);
    setColumnarray2([
      ...clm2.sort((a, b) => {
        return +a.co - +b.co;
      }),
    ]);
    oneRef.current = false;
  };

  const submitColumnbtn = () => {
    var colm = columnarray;
    var colm2 = columnarray2;
    var sub = [];
    // var count = 0;
    for (var i = 0; i < colm2.length; i++) {
      for (var j = 0; j < colm.length; j++) {
        if (colm2[i].label === colm[j].label) {
          sub.push({ name: colm[j].name, co: colm2[i].co });
        }
      }
    }

    if (pref === "user") {
      json.userPreference[2].column = sub;
      json.userPreference[1].table.name = tablename;
      let sort = { sort: { asc: "true", column: "id", init: "true" } };
      json.userPreference.push(sort);
      let filter = {
        filter: {
          co: "",
          cl: "",
          mc: "",
          an: "",
          ct: "",
          af: "",
          rf: { id: "", value: "" },
          ch: [],
        },
        timeLine: "",
      };
      json.userPreference.push(filter);
      axios
        .post(loca + "/marketplace/set/user/preference/", json, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then((resp) => {
          var sysrecord = resp.data;
          handleClose();
        });
    } else if (pref === "owner") {
      json.ownerPreference[2].column = sub;
      json.ownerPreference[1].table.name = tablename;
      axios
        .post(loca + "/marketplace/set/owner/preference/", json, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then((resp) => {
          handleClose();
        });
    }
  };

  const selected = (index, clm) => {
    if (clm === "clm1") {
      setIsColArrSelected(true);
      setIsColArr2Selected(false);
      let clm1 = document.getElementById("clm1");
      for (let i = 0; i < columnarray.length; i++) {
        if (i === index) {
          clm1.children.item(i).classList.add("selected-row");
        } else {
          clm1.children.item(i).classList.remove("selected-row");
        }
      }
      let clm2 = document.getElementById("clm2");
      for (let i = 0; i < columnarray2.length; i++) {
        clm2.children.item(i).classList.remove("selected-row");
      }
    } else {
      setIsColArrSelected(false);
      setIsColArr2Selected(true);
      ind.current = index;
      let clm2 = document.getElementById("clm2");
      for (let i = 0; i < columnarray2.length; i++) {
        if (i === index) {
          clm2.children.item(i).classList.add("selected-row");
        } else {
          clm2.children.item(i).classList.remove("selected-row");
        }
      }
      let clm1 = document.getElementById("clm1");
      for (let i = 0; i < columnarray.length; i++) {
        clm1.children.item(i).classList.remove("selected-row");
      }
    }
  };

  const setColumnOrder = (pm) => {
    var clm = [];
    var col_odr = ind.current;
    var col_odr2 = 0;

    if (pm) {
      if (col_odr < columnarray2.length - 1) {
        col_odr2 = col_odr + 1;
        let clm2 = document.getElementById("clm2");
        for (let i = 0; i < columnarray2.length; i++) {
          if (i !== col_odr2 && i !== col_odr) {
            clm.push(columnarray2[i]);
          } else if (i === col_odr2) {
            clm2.children.item(col_odr2).classList.add("selected-row");
            let cjj = columnarray2[i];
            cjj.co = (col_odr + 1).toString();
            clm.push(cjj);
          } else if (i === col_odr) {
            clm2.children.item(col_odr).classList.remove("selected-row");
            let cjj = columnarray2[i];
            cjj.co = (col_odr2 + 1).toString();
            clm.push(cjj);
          }
        }
        clm.sort((a, b) => (a.co > b.co ? 1 : a.co < b.co ? -1 : 0));
        ind.current = col_odr2;
        setColumnarray2([...clm]);
      }
    } else {
      if (col_odr > 0) {
        col_odr2 = col_odr - 1;
        let clm2 = document.getElementById("clm2");
        for (let i = 0; i < columnarray2.length; i++) {
          if (i !== col_odr2 && i !== col_odr) {
            clm.push(columnarray2[i]);
          } else if (i === col_odr2) {
            clm2.children.item(col_odr2).classList.add("selected-row");
            let cjj = columnarray2[i];
            cjj.co = (col_odr + 1).toString();
            clm.push(cjj);
          } else if (i === col_odr) {
            clm2.children.item(col_odr).classList.remove("selected-row");
            let cjj = columnarray2[i];
            cjj.co = (col_odr2 + 1).toString();
            clm.push(cjj);
          }
        }
        clm.sort((a, b) => (a.co > b.co ? 1 : a.co < b.co ? -1 : 0));
        ind.current = col_odr2;
        setColumnarray2([...clm]);
      }
    }
  };

  const shiftRight = () => {
    let clm = columnarray;
    let clm2 = columnarray2;
    if (document.getElementsByClassName("selected-row").item(0)) {
      let lab = document
        .getElementsByClassName("selected-row")
        .item(0).innerText;
      for (let i = 0; i < columnarray.length; i++) {
        if (clm[i].label === lab) {
          if (clm[i].type !== "ui") {
            clm[i].check = true;
          }
          let n_co = clm2.length + 1;
          let n = { label: lab, co: n_co };
          clm2.push(n);
        }
      }
      setIsColArrSelected(false);
      setColumnArray([...clm]);
      setColumnarray2([...clm2]);
    }
  };

  const shiftLeft = () => {
    let clm = columnarray;
    let clm2 = columnarray2;
    if (document.getElementsByClassName("selected-row").item(0)) {
      let lab = document
        .getElementsByClassName("selected-row")
        .item(0).innerText;
      for (let i = 0; i < columnarray2.length; i++) {
        if (clm2[i].label === lab) {
          clm2.splice(i, 1);
        }
      }
      for (let i = 0; i < columnarray.length; i++) {
        if (clm[i].label === lab) {
          clm[i].check = false;
        }
      }
      setIsColArr2Selected(false);
      setColumnArray([...clm]);
      setColumnarray2([...clm2]);
    }
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <div className="row fr justify-content-center">
        <div id="clm1" className="col-md box-pref">
          {columnarray.length > 0 &&
            columnarray.map((obj, obj_i) => (
              <div key={obj_i}>
                {obj.check === false && (
                  <div className="row fr">
                    <p
                      style={{ margin: 0 }}
                      className="columnarray2 heading"
                      onClick={() => {
                        selected(obj_i, "clm1");
                      }}
                    >
                      {obj.label}
                    </p>
                  </div>
                )}
              </div>
            ))}
        </div>
        {!(isMobile || isTablet) ? (
          <div className="up-down">
            <div>
              <button
                disabled={isColArr2Selected}
                className="ryt-left-btn"
                onClick={() => {
                  shiftRight();
                }}
              >
                {">"}
              </button>
            </div>
            <div>
              <button
                className="ryt-left-btn"
                disabled={isColArrSelected}
                onClick={() => {
                  shiftLeft();
                }}
              >
                {"<"}
              </button>
            </div>
          </div>
        ) : (
          <div className="up_down d-flex justify-content-center">
            <button
              disabled={isColArrSelected}
              className="up-down-view"
              onClick={(e) => shiftLeft()}
            >
              {">"}
            </button>
            <button
              disabled={isColArr2Selected}
              className="up-down-view"
              onClick={(e) => shiftRight()}
            >
              {"<"}
            </button>
          </div>
        )}
        <div id="clm2" className="col-md box-pref">
          {columnarray2.map((obj, index) => (
            <p
              style={{ margin: 0 }}
              key={index}
              className="columnarray2"
              onClick={() => {
                selected(index, "clm2");
              }}
              value={obj.label}
            >
              {obj.label}
            </p>
          ))}
        </div>
        <div className="col-md-1 up_down_btn">
          <button
            className="up-down-view"
            onClick={(e) => setColumnOrder(false)}
          >
            {">"}
          </button>

          <button
            className="up-down-view"
            onClick={(e) => setColumnOrder(true)}
          >
            {"<"}
          </button>
        </div>
        <div className="mt-2 ms-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => submitColumnbtn()}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md"></div>
      </div>
    </div>
  );
};

export default PrefCompo;
