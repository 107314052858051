import React, { useEffect, useRef, useState } from "react";
import { Outlet, useBlocker, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

export default function TestCompo() {
  return(
    <div className="container">
      <div style={{background:"grey",height:"200px", border:"3px solid black"}}>
        <div>Top</div>
        <div>Bottom</div>
      </div>
      <div style={{background:"grey",height:"200px", border:"3px solid black"}}>
        <div>Top</div>
        <div>Bottom</div>
      </div>
      <div style={{background:"grey",height:"200px", border:"3px solid black"}}>
        <div>Top</div>
        <div>Bottom</div>
      </div>
      <div style={{background:"grey",height:"200px", border:"3px solid black"}}>
        <div>Top</div>
        <div>Bottom</div>
      </div>
      <div style={{background:"grey",height:"200px", border:"3px solid black"}}>
        <div>Top</div>
        <div>Bottom</div>
      </div>
      <div style={{background:"grey",height:"200px", border:"3px solid black"}}>
        <div>Top</div>
        <div>Bottom</div>
      </div>
      <div style={{background:"grey",height:"200px", border:"3px solid black"}}>
        <div>Top</div>
        <div>Bottom</div>
      </div>
    </div>
  )
}
